<template>
  <b-row class="bg-primary mb-3 text-center">
    <b-col lg="1" class="p-4 mt-auto mb-auto">
      <b-button
        size="sm"
        class="rounded-circle card-link go-back"
        @click="goBack">
        <b-icon-arrow-left />
      </b-button>
    </b-col>
    <b-col lg="10" class="p-4">
      <h2 class="text-white contactAExpertText">
        <b-link target="_blank" class="card-link" href="/contact-us"
          ><span class="bookExpert">Book a Teams Tour</span></b-link>
          with one of our experts
      </h2>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton,
  BCol,
  BIconArrowLeft,
  BLink,
  BRow,
} from 'bootstrap-vue';

export default {
  components: {
    BButton,
    BCol,
    BIconArrowLeft,
    BLink,
    BRow,
  },
  methods: {
    goBack() {
      if (document.referrer.includes('identity.afrigis.co.za')) {
        window.history.go(-2);
      }
      window.history.back();
    },
  },
};
</script>

<style>
.bookExpert{
 background-color: #addbe5;
}
.go-back {
  background-color: #addbe5;
  color: darkblue;
  }
@media screen and (max-width: 600px) {
  .contactAExpertText {
    font-size: 1.2rem!important;
  }
}
</style>
