<template>
  <div>
    <b-alert class="container container-between-stickies" show variant="info" v-if="!OidcUser">
      <b-spinner label="Loading..." small />
      Loading User Information
    </b-alert>
    <router-view class="container-between-stickies" v-else />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { AuthMixin } from '@afrigis/iris-vuex-auth-store';
import { BAlert, BSpinner } from 'bootstrap-vue';

export default {
  components: {
    BAlert,
    BSpinner,
  },
  computed: {
    ...mapGetters({
      OidcUser: 'authStore/OidcUser',
    }),
    version: () => process.env.VUE_APP_VERSION,
  },
  methods: {
    ...mapActions({
      AuditWrite: 'auditStore/Log',
    }),
  },
  mixins: [AuthMixin],
  mounted() {
    // Get View Name, and add space after each Capital letter eg. 'Details Search'.
    const capabilityName = this.$router.currentRoute.name.replace(/([A-Z])/g, ' $1').trim();
    this.AuditWrite(capabilityName.toLowerCase());
  },
  name: 'App',
};
</script>

<style>
</style>
