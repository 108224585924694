import Vue from 'vue';
import VueRouter from 'vue-router';
import AddressSearch from '@/views/AddressSearch.vue';
import DetailsSearch from '@/views/DetailsSearch.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/address-search',
    name: 'AddressSearch',
    component: AddressSearch,
  },
  {
    path: '/address-search/details',
    name: 'DetailsSearch',
    component: DetailsSearch,
  },
  {
    component: () => import(/* webpackChunkName: "Error" */'@/views/Error.vue'),
    meta: {
      isPublic: true,
    },
    name: 'Error',
    path: '/error',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
