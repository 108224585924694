<template>
  <b-container fluid>
    <b-row>
      <b-col md="4" class="p-3 text-left">
        <h1 class="headerText">Address Search</h1>
      </b-col>
      <b-col md="4" class="ml-auto p-3 text-right appCtrlButtons">
        <b-button
          title="Share This App"
          variant="outline-secondary"
          @click="shareViaWebShare"
        >
          <b-icon-share />
        </b-button>
        <b-modal
          id="modal-1"
          title="Share This App"
          ref="fallback-modal"
          @ok="handleOk"
        >
          <b-form-textarea
            id="fallback"
            ref="toCopy"
            v-model="shareUrl"
            rows="1"
            max-rows="6"
          ></b-form-textarea>
          <template #modal-footer="{ ok }">
            <b-button size="sm" variant="primary" @click="ok()">
              Copy URL to Clipboard
            </b-button>
          </template>
        </b-modal>

        <b-button @click="startTour" variant="outline-secondary">
          Start Tour
        </b-button>
      </b-col>
    </b-row>
    <v-tour
      class="addressTour"
      name="addressSearchTour"
      :options="tourOptions"
      :steps="addressSearchTourSteps"
    />
    <contact-expert />
    <b-row>
      <!--Column 1 -->
      <b-col class="mb-3" lg="4">
        <b-card class="mb-3 resultBackground" header-tag="header">
          <template #header>
            <h4>Interact with Address Search</h4>
          </template>
          <h5>Find an address</h5>
          <form autocomplete="off" @submit.prevent="submitSearch">
            <ag-autocomplete
              autofocus
              id="autocomplete"
              class="autocomplete-component"
              :options="suggestions"
              placeholder="Address"
              :processing="loadingSuggestions"
              :text="searchText"
              @cleared="clearAddress"
              @onChosen="onChosen"
              @onFocus="fetchAwsCredentials"
              @onInput="autocomplete"
              @onSearch="startSearch"
            />
          </form>
          <b-alert
            class="mt-5"
            show
            v-if="hasError"
            variant="danger"
            dismissible
          >
            {{ searchError }}
          </b-alert>
            <b-row class="mt-5">
              <b-col id="result">
                <h5>Results</h5>
                <p v-if="!!detailedResults">{{ detailedResults.formatted_address }}</p>
              </b-col>
            </b-row>
        </b-card>
      </b-col>
      <!--Column 2 -->
      <b-col class="mb-3" lg="4">
        <b-card
          id="trust-us-card"
          :style="{ backgroundImage: `url(${backgroundImage})` }"
          text-variant="white"
          class="mb-3"
        >
          <div class="content">
            <h5>Trust us for</h5>
            <ul>
              <li>SABS and ISO compliant Address data.</li>
              <li>Accurate and verified address results returned.</li>
              <li>
                Components available via API to integrate into your own solutions.
              </li>
              <li>Over 50 Million searchable address combinations.</li>
            </ul>
          </div>
        </b-card>
        <b-card
          id="addressDetails"
          class="remove-border-card">
          <h5>Possible uses:</h5>
          <ul>
            <li>
              Populate accurate address details for deliveries.
            </li>
            <li>
              Create a map for your address.
            </li>
            <li>
              Powerful risk reports for a specific address.
            </li>
            <li>
              Navigation and routing.
            </li>
            <li>
              Available via API to integrate into your own solutions.
            </li>
          </ul>
          <a class="card-link"
            target="_blank"
            href="https://developers.afrigis.co.za/portfolio/search/">
            <b-link
              class="linkBoarder"
              >View Technical Documentation <b-icon-arrow-right
            /></b-link>
          </a>
        </b-card>
      </b-col>
      <!--Column 3 -->
      <b-col lg="4">
        <b-card class="mb-3" header-tag="header">
          <template #header>
            <h4>Search Result Sample Response</h4>
          </template>
          <b-card-text>
            <b-form-textarea
              plaintext
              no-resize
              v-model="jsonSampleStr"
              style="height: 400px"
            >
            </b-form-textarea>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Vue from 'vue';
import VueTour from 'vue-tour';

import AgAutocomplete from '@afrigis/afrigis-vue-autocomplete';
import addressSearch from '@/mixins/addressSearch';
import {
  BButton,
  BCard,
  BCol,
  BContainer,
  BIconArrowRight,
  BIconShare,
  BFormTextarea,
  BRow,
} from 'bootstrap-vue';
import oidcAuth from '@/mixins/oidcAuth';
import ContactExpert from '@/components/ContactExpert.vue';

const backgroundImage = require('@/assets/TTB_Image_1920x300.webp');

Vue.use(VueTour);

export default {
  components: {
    AgAutocomplete,
    BButton,
    BCard,
    BCol,
    BContainer,
    BIconArrowRight,
    BIconShare,
    BFormTextarea,
    BRow,
    ContactExpert,
  },
  computed: {
    addressComponents() {
      if (!this.detailedResults) {
        return null;
      }
      return this.detailedResults.address_components;
    },
    addressSearchTourSteps() {
      return [
        {
          content:
            'Use this input field to type in the address you want to find and click on the Search button. After a search, the matching suggested addresses will be displayed below the Search Box.',
          header: {
            title: 'Address Search Input Field',
          },
          params: {
            placement: 'right-end',
          },
          target: '#autocomplete',
        },
        {
          content: 'Detailed description of address search and its uses.',
          header: {
            title: 'Detail Description',
          },
          params: {
            placement: 'auto',
          },
          target: '#addressDetails',
        },
      ];
    },
    backgroundImage: () => backgroundImage,
    jsonSampleStr() {
      return JSON.stringify(this.sampleJson, null, 1);
    },
    shareUrl() {
      const { protocol, host, pathname } = window.location;
      const url = [`${protocol}//${host}${pathname}`];
      if (this.searchText) {
        url.push(`?query=${this.searchText}`);
      }
      return url.join('');
    },
    isLoading() {
      return this.loadingSuggestions;
    },
    tableData() {
      return this.addressComponents.map((ac) => ({
        ...ac,
        types: ac.types.join(','),
      }));
    },
    tourOptions() {
      return {
        highlight: true,
        labels: {
          buttonSkip: 'Skip tour (Esc)',
          buttonPrevious: 'Previous',
          buttonNext: 'Next',
          buttonStop: 'Thanks',
        },
        useKeyboardNavigation: true,
      };
    },
  },
  data() {
    return {
      searchText: '',
      seoId: null,
      sampleJson: {
        result: [
          {
            place_id: '2XIAs5De9f_eEXNFubwV-ZXI41F281017',
            seoid: '2XIAs5De9f_eEXNFubwV-ZXI41F281017',
            sfid: '5c3659ba-335b-5ebd-e053-0d05000aa120',
            description:
              '446 Rigel Avenue South, Erasmusrand, Pretoria, Gauteng, 0181',
            title: '446 Rigel Avenue South',
            types: ['street_address_level_1', 'street_address'],
          },
        ],
        code: 200,
        message: 'OK',
        source: 'places.api-autocomplete',
      },
    };
  },
  destroyed() {
    window.removeEventListener('iris:share-capability', this.shareViaWebShare);
    window.removeEventListener('iris:start-tour', this.startTour);
  },
  methods: {
    isTourOn() {
      const { tour } = this.$route.query;
      if (!tour) {
        return false;
      }
      return /^true$/i.test(tour.toLowerCase());
    },
    clearAddress() {
      this.searchText = '';
      this.seoId = null;
      this.clearDetailedResults();
      this.clearSuggestions();
    },
    handleOk() {
      const Url = this.$refs.toCopy;
      Url.innerHTML = this.shareUrl;
      Url.select();
      document.execCommand('copy');
      this.$bvModal.msgBoxOk('URL Copied to Clipboard successfully', {
        title: 'Confirmation',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true,
      });
    },
    onChosen(data) {
      this.searchError = null;
      this.clearAddress();
      this.clearDetailedResults();
      this.clearSuggestions();
      this.searchText = data.description;
      this.seoId = data.seoid;
      this.doSearchDetail(this.seoId);
    },
    shareViaWebShare() {
      if (navigator.share) {
        navigator
          .share({
            title: document.title,
            url: this.shareUrl,
          })
          .then()
          .catch(() => {
            // Handle the error!
          });
      } else {
        this.$refs['fallback-modal'].show();
      }
    },
    startSearch(data) {
      this.fetchAwsCredentials();
      this.doSearch(data);
    },
    startTour() {
      this.$tours.addressSearchTour.start();
    },
    submitSearch() {
      this.searchLastEnteredText();
    },
    tourConfigure() {
      const hasSeenTour = JSON.parse(
        localStorage.getItem('hasSeenAddressSearchTour'),
      );
      if (!hasSeenTour || this.isTourOn()) {
        this.startTour();
        localStorage.setItem('hasSeenAddressSearchTour', true);
      }
    },
  },
  mixins: [addressSearch, oidcAuth],
  mounted() {
    window.addEventListener('iris:share-capability', this.shareViaWebShare);
    window.addEventListener('iris:start-tour', this.startTour);
    const { query } = this.$route.query;
    if (query) {
      this.searchText = query;
    }
    this.tourConfigure();
  },
  name: 'AddressSearch',
};
</script>

<style src="vue-tour/dist/vue-tour.css"></style>
<style scoped>
#fallback {
  pointer-events: none;
  background-color: lightgrey;
}
.overlay {
  position: absolute;
  width: 100%;
  z-index: 1000;
}
.addressTour {
  z-index: 500;
}
.resultBackground {
  background-color: #addbe5;
  color: #033a63;
}
.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4);
}

.autocomplete-component .list-group {
  position: absolute;
}
@media screen and (max-width: 400px) {
  .appCtrlButtons {
    display: none
  }
}

#trust-us-card {
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 250px;
  border-bottom:0;

  border-color: white;
}
#trust-us-card .content{
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
}

.remove-border-card {
  border-top: 0;
  border-color: white;
}

</style>
